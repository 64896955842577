import { Component } from "react";
import { match } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../../constants/config";
import { getYearAndMonth } from "../../../../util/result";
import Cookies from "js-cookie";
import { History, LocationState } from "history";
import moment from "moment";
import {
  EditOutlined,
  DownloadOutlined,
  GlobalOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, Tooltip, Modal, Select } from "antd";
import {
  EXPERT_PRODUCTS,
  PRODUCTS,
  EXPERT_PRODUCTS_WITHOUT_REPORT,
  EXPERT_PRODUCTS_FOR_ADMIN_PANEL,
  PRODUCTS_WITH_CHILD_INFO_ONLY,
} from "../../../../constants/common";
import NotificationModal from "../../../common/NotificationModal";
import VideoModal from "../../../common/VideoModal";
import AssignTherapistModal from "../../../common/AssignTherapistModal";
import {
  SNOWFLAKE,
  NORMALIZE_LANGUAGE_FOR_MAILGUN_EMAIL_TEMPLATE,
} from "../../../../util/common";
import Uploader from "../../../../util/uploader";
import CommentModal from "../../../common/CommentModal";
import {
  Container,
  ContentContainer,
  ContentSubtitle,
  ChildAgeSubTitle,
  Input,
  CancelButton,
  SaveButton,
  LinkButtonInEdit,
  TextWrapLinkButtonInEdit,
  UtcNotice,
} from "../../styles";
import {
  QuestionnaireAndResultPage,
  SubContainer,
  CompletedContainer,
  SendReportButton,
  ChildInfoContainer,
  ChildInfoTitle,
  TherapistContainer,
  TherapistTitle,
  BasicInfoContainer,
  BasicInfoTitle,
  MoreInfoContainer,
  MoreInfoTitle,
  VideoContainer,
  VideoTitle,
  ReportContainer,
  ReportTitle,
  BasicInfoContent,
  ChildName,
  ChildAge,
  FullResult,
  Report,
  Reject,
  Submit,
  Video,
  Assign,
  Edit,
  AdminVideo,
  AdminReport,
} from "./style";
import { JS_COOKIE } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";
import { client } from "graphql/ApolloClient";
import { ADD_CHILDREN_MUTATION } from "graphql/mutations/addChildren";
import { WindowWithDataLoaded } from "components/User/Evaluation/CorePro/CoreProReport";
import { handleSendNewReportEmail } from "../../../../components/Admin/User/Edit/components/newUploadReportEmailSender";
import { number } from "prop-types";
interface Match {
  id: string;
}

interface TherapistType {
  id: string;
  firstName: string;
  lastName: string;
  UUID: string;
}

interface ResultEditProps {
  match: match<Match>;
  history: History<LocationState>;
}

interface childInfoType {
  childBirthdayDay: string;
  childBirthdayMonth: string;
  childBirthdayYear: string;
  childName: string;
  sex: string;
}

const initialChildInfo = {
  childBirthdayDay: "",
  childBirthdayMonth: "",
  childBirthdayYear: "",
  childName: "",
  sex: "",
};

interface SummaryType {
  database: string;
  calculated: string;
}

const initialSummary = {
  database: "",
  calculated: "",
};

interface ResultEditStates {
  [key: string]:
    | string
    | boolean
    | Array<TherapistType>
    | childInfoType
    | SummaryType
    | number;
  id: string;
  therapistList: Array<TherapistType>;
  consultationLink: string;
  consultationTime: string;
  CoreProReportId: string;
  completed: boolean;
  createdAt: string;
  startAt: string;
  finishAt: string;
  uploadAt: string;
  currentStep: string;
  productId: string;
  productName: string;
  childInfo: childInfoType;
  summary: SummaryType;
  lastTherapistId: string;
  therapistId: string;
  transactionId: string;
  therapistFirstName: string;
  therapistLastName: string;
  userId: string;
  video1: string;
  video1comment: string;
  encodedVideoName1: string;
  video1aws: boolean;
  completed1: number;
  video2: string;
  video2comment: string;
  encodedVideoName2: string;
  video2aws: boolean;
  completed2: number;
  videoId: string;
  message: string;
  showVideo: boolean;
  showVideoComment: boolean;
  showReportComment: boolean;
  videoComment: string;
  reportComment: string;
  showNotification: boolean;
  showAssignTherapist: boolean;
  notificationStatus: boolean;
  userFirstName: string;
  userLastName: string;
  expertReportId: string;
  expertReportLastSent: string;
  userEmail: string;
  language: string;
  reportLanguage: string;
  userLanguage: string;
}

type Props = ResultEditProps & WithTranslation;
class ResultEdit extends Component<Props, ResultEditStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: "",
      therapistList: [],
      consultationLink: "",
      consultationTime: "",
      CoreProReportId: "",
      completed: false,
      createdAt: "",
      startAt: "",
      finishAt: "",
      uploadAt: "",
      currentStep: "",
      productId: "",
      productName: "",
      childInfo: initialChildInfo,
      summary: initialSummary,
      lastTherapistId: "",
      therapistId: "",
      therapistFirstName: "",
      therapistLastName: "",
      transactionId: "",
      userId: "",
      video1: "",
      video1comment: "",
      encodedVideoName1: "",
      video1aws: false,
      completed1: 0,
      video2: "",
      video2comment: "",
      encodedVideoName2: "",
      video2aws: false,
      completed2: 0,
      videoId: "",
      message: "",
      showVideo: false,
      showVideoComment: false,
      showReportComment: false,
      videoComment: "",
      reportComment: "",
      showNotification: false,
      showAssignTherapist: false,
      notificationStatus: false,
      userFirstName: "",
      userLastName: "",
      expertReportId: "",
      expertReportLastSent: "",
      isModalVisible: false,
      reportLanguage: "en",
      isAssignModalVisible: false,
      newTherapistId: "",
      sendReport: false,
      userEmail: "",
      userLanguage: "",
    };
  }

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleYes = () => {
    const { userEmail, reportLanguage } = this.state;
    const { i18n } = this.props;
    this.setState({ isModalVisible: false, sendReport: true });
    this.handleSetCompletedTrue();
    handleSendNewReportEmail(
      NORMALIZE_LANGUAGE_FOR_MAILGUN_EMAIL_TEMPLATE(reportLanguage),
      userEmail
    );
    // Update last email sent timestamp in backend
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const { id } = this.props.match.params;

    axios
      .post(
        api_address + "api/admin/result/updateLastEmailSent",
        { id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("res from backend:", res.data);
        this.setState({
          expertReportLastSent: res.data.form.last_email_sent,
        });
      })
      .catch((err) => {
        console.error("Error updating last email sent timestamp:", err);
      });
  };

  handleNo = () => {
    this.setState({ isModalVisible: false });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<ResultEditStates>,
    snapshot?: any
  ): void {
    if (this.state.therapistId !== prevState.therapistId) {
      const { therapistId } = this.state;
      const { therapistList } = this.state;
      const therapist = therapistList.filter(
        (therapist) => therapist.id === therapistId
      );
      if (therapist.length > 0) {
        this.setState({
          therapistFirstName: therapist[0].firstName,
          therapistLastName: therapist[0].lastName,
        });
      }
    }
  }

  componentDidMount = async () => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const res = await axios.get(api_address + "api/admin/result/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { result, therapistList } = res.data;
      const {
        steps,
        therapistId,
        video1,
        video1comment,
        video1aws,
        video2,
        video2comment,
        video2aws,
        finishAt,
        uploadAt,
        startAt,
        consultationTime,
        summary,
        userLanguage,
        expertReportId,
        expertReportLastSent,
      } = result;
      let therapistFirstName = "",
        therapistLastName = "";

      if (therapistId) {
        ({ therapistFirstName, therapistLastName } =
          await this.getTherapistName(therapistId));
      }
      this.setState({
        ...result,
        id,
        lastTherapistId: therapistId,
        therapistList,
        therapistFirstName: therapistFirstName,
        therapistLastName: therapistLastName,
        startAt: startAt && moment(startAt).format("YYYY-MM-DDThh:mm"),
        finishAt: finishAt && moment(finishAt).format("YYYY-MM-DDThh:mm"),
        uploadAt: uploadAt && moment(finishAt).format("YYYY-MM-DDThh:mm"),
        consultationTime:
          consultationTime &&
          moment(consultationTime).format("YYYY-MM-DDThh:mm"),
        childInfo: steps.child_info.values,
        encodedVideoName1: video1aws ? video1 : "",
        encodedVideoName2: video2aws ? video2 : "",
        summary,
        sendReport: false,
        reportLanguage: userLanguage,
        expertReportLastSent: expertReportLastSent,
      });
    } catch (err: any) {
      console.error(err.message);
    }
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showNotification: true,
    });
    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 3000);
  };

  handleResultMethodChange = (value: string) => {
    if (value != null)
      this.setState({
        resultMethod: value,
      });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    this.setState({
      [name]: value,
    });
  };

  handleOpenVideoModal = () => {
    this.setState({
      showVideo: true,
    });
  };
  handleDeleteVideo = async (videoKey: "video1" | "video2") => {
    try {
      const { transactionId } = this.state;
      const data = {
        transactionid: transactionId,
        video1: videoKey === "video1" ? "" : this.state.video1, // clear video1 if video1 is being deleted
        video2: videoKey === "video2" ? "" : this.state.video2, // clear video2 if video2 is being deleted
        video1aws: videoKey === "video1" ? null : this.state.video1aws, // clear video1aws if video1 is being deleted
        video2aws: videoKey === "video2" ? null : this.state.video2aws, // clear video2aws if video2 is being deleted
        video1comment: videoKey === "video1" ? null : this.state.video1comment, // clear video1comment if video1 is being deleted
        video2comment: videoKey === "video2" ? null : this.state.video2comment, // clear video2comment if video2 is being deleted
      };

      const token = Cookies.get("token");
      const res = await axios.post(
        api_address + "api/results/videoUploadByAdmin",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { videoid } = res.data.result;
      this.setState({
        showVideo: true,
        videoId: videoid,
        [videoKey]: "", // clear the deleted video in state
        [`${videoKey}aws`]: false, // reset AWS flag for the deleted video
      });
    } catch (err: any) {
      this.showNotification(err.message, false);
    }
  };

  handleSaveVideo = async () => {
    try {
      const {
        video1,
        video2,
        encodedVideoName1,
        encodedVideoName2,
        video1aws,
        video2aws,
        video1comment,
        video2comment,
        transactionId,
      } = this.state;
      const data = {
        transactionid: transactionId,
        video1: video1aws ? encodedVideoName1 : video1,
        video2: video2aws ? encodedVideoName2 : video2,
        video1aws,
        video2aws,
        video1comment,
        video2comment,
      };
      const token = Cookies.get("token");
      const res = await axios.post(
        api_address + "api/results/videoUploadByAdmin",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { videoid } = res.data.result;
      this.setState({
        showVideo: true,
        videoId: videoid,
      });
    } catch (err: any) {
      this.showNotification(err.message, false);
    }
  };

  handleUploadFile = (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      const { files, id } = e.currentTarget;
      if (!files || files.length === 0) {
        reject(new Error("No files to upload"));
        return;
      }

      const f = files[0];
      const uploadClass = this;
      const {
        transactionId,
        video1,
        video2,
        encodedVideoName1,
        encodedVideoName2,
        video1aws,
        video2aws,
      } = this.state;

      const isVideo1 = id === "Upload_1";
      const isVideo2 = id === "Upload_2";

      const flakeId = SNOWFLAKE.gen();
      const splitFileName = f.name.split(".");
      const fileNameExtension = "." + splitFileName[splitFileName.length - 1];
      const encodedFileName = flakeId + fileNameExtension;

      const updatedVideo1 = isVideo1 ? f.name : video1;
      const updatedVideo1AWS = isVideo1 ? true : video1aws;
      const updatedEncodedVideoName1 = isVideo1
        ? encodedFileName
        : encodedVideoName1;
      const updatedVideo2 = isVideo2 ? f.name : video2;
      const updatedVideo2AWS = isVideo2 ? true : video2aws;
      const updatedEncodedVideoName2 = isVideo2
        ? encodedFileName
        : encodedVideoName2;

      Uploader(f, transactionId, encodedFileName)
        .on("httpUploadProgress", function (evt) {
          const uploaded = Math.round((evt.loaded / evt.total) * 100);

          if (isVideo1) {
            console.log("Progress for Video 1:", uploaded);
            uploadClass.setState({ completed1: uploaded });
          } else if (isVideo2) {
            console.log("Progress for Video 2:", uploaded);
            uploadClass.setState({ completed2: uploaded });
          }

          if (uploaded === 100) {
            if (isVideo1) {
              uploadClass.setState({
                video1: updatedVideo1,
                video1aws: updatedVideo1AWS,
                encodedVideoName1: updatedEncodedVideoName1,
              });
            }
            if (isVideo2) {
              uploadClass.setState({
                video2: updatedVideo2,
                video2aws: updatedVideo2AWS,
                encodedVideoName2: updatedEncodedVideoName2,
              });
            }
            resolve();
          }
        })
        .send(function (err, data) {
          if (err) {
            reject(err);
            return;
          }
        });
    });
  };

  handleCloseVideoModal = () => {
    this.setState({
      showVideo: false,
    });
  };

  handleOpenAssignTherapistModal = () => {
    this.setState({
      showAssignTherapist: true,
      isAssignModalVisible: true,
    });
  };

  handleChangeTherapist = (therapistId: string) => {
    this.setState({ therapistId: therapistId });
  };

  handleSetCompletedTrue = () => {
    this.setState({ completed: true });
  };

  handleSetCompletedFalse = () => {
    this.setState({ completed: false });
  };

  handleSubmitAssignTherapist = async () => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const {
        id,
        videoId,
        therapistId,
        therapistList,
        childInfo,
        productId,
        userId,
      } = this.state;
      const {
        childName,
        childBirthdayDay,
        childBirthdayMonth,
        childBirthdayYear,
        sex,
      } = childInfo;
      const childDob =
        childBirthdayYear + "-" + childBirthdayMonth + "-" + childBirthdayDay;
      var gender = "undefined";
      if (sex === "1") {
        gender = "male";
      } else if (sex === "2") {
        gender = "female";
      }

      const data = { therapistId, resultId: id, id: videoId };
      const userData = await axios.get(
        api_address + "api/admin/user/" + userId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const userUuid = userData.data.user.UUID;

      const productData = await axios.get(
        api_address + "api/admin/product/" + productId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const productUuid = productData.data.product.uid;
      const therapist = therapistList.filter(
        (therapist) => therapist.id === therapistId
      );
      const addChildrenRes = await client.mutate({
        mutation: ADD_CHILDREN_MUTATION,
        variables: {
          childName: childName,
          gender: gender,
          dob: childDob,
          therapistUuid: therapist[0].UUID,
          uuid: userUuid,
          productUuid: productUuid,
        },
      });
      const addChildrenReturn = addChildrenRes.data.addChild;
      if (addChildrenReturn.returnMessage.status == "SUCCESS") {
        this.showNotification(addChildrenReturn.returnMessage.message, true);
        await new Promise((r) => setTimeout(r, 2000));
      } else {
        this.showNotification(addChildrenReturn.returnMessage.message, false);
        await new Promise((r) => setTimeout(r, 2000));
      }

      await axios.post(api_address + "api/admin/result/assignTherapist", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({
        lastTherapistId: therapistId,
        showVideo: false,
        showAssignTherapist: false,
      });
      this.showNotification("assign successful", true);
    } catch (err: any) {
      console.error(err);
    }
  };

  handleCloseAssignTherapistModal = () => {
    this.setState((state) => ({
      therapistId: state.lastTherapistId,
      showAssignTherapist: false,
    }));
  };

  handleRejectVideo = () => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const { id } = this.props.match.params;
    const { videoComment } = this.state;
    const data = {
      resultId: id,
      comment: videoComment,
    };
    axios
      .post(api_address + "api/admin/result/rejectVideo", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        this.setState({
          showVideoComment: false,
        });
      })
      .catch((err) => console.error(err));
  };

  onVideoCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    this.setState({
      videoComment: value,
    });
  };

  onReportCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    this.setState({
      reportComment: value,
    });
  };

  handleOpenVideoComment = () => {
    this.setState({
      showVideoComment: true,
    });
  };

  handleCloseVideoComment = () => {
    this.setState({
      showVideoComment: false,
    });
  };

  handleOpenReportComment = () => {
    this.setState({
      showReportComment: true,
    });
  };

  handleCloseReportComment = () => {
    this.setState({
      showReportComment: false,
    });
  };

  handleSubmitReport = () => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const { id } = this.props.match.params;
    const { reportComment, videoId, productName } = this.state;
    const data = {
      id,
      videoId,
      reportComment,
    };
    axios
      .post(api_address + "api/admin/result/submitReport", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        this.setState({
          showReportComment: false,
        });
        if (productName === PRODUCTS.CorePro) {
          this.showNotification(
            "Submit successful, remember to set the consultation time and zoom link",
            true
          );
          window.scrollTo(0, 0);
        } else if (productName === PRODUCTS.CPC) {
          this.showNotification(
            "submit successful, remember to prepare for the onboarding",
            true
          );
        }
      })
      .catch((err) => console.error(err));
  };

  handleEditReport = () => {
    const { id } = this.props.match.params;
    this.props.history.push("/admin/report-generation/" + id, "_blank");
  };

  onCancelClick = () => {
    this.props.history.push("/admin/result");
  };

  onSaveClick = (completed) => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const { consultationLink, consultationTime } = this.state;
      axios
        .put(
          api_address + "api/admin/result/" + id,
          {
            consultationLink,
            consultationTime,
            completed,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const { status, message } = res.data;
          this.showNotification(message, status);
        })
        .catch((err) => {
          this.showNotification(err.message, false);
        });
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  getTherapistName = async (
    therapistId: number
  ): Promise<{
    therapistFirstName: string;
    therapistLastName: string;
  }> => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    const res = await axios.get(
      api_address + "api/admin/therapist/" + therapistId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const {
      therapist: { therapistData },
    } = res.data;
    return {
      therapistFirstName: therapistData.firstName,
      therapistLastName: therapistData.lastName,
    };
  };

  handleChangeReportLanguage = async (language: string) => {
    this.setState({ reportLanguage: language });
    console.log(`Selected report language changed to ${language}`);
  };

  handleDownloadReport = () => {
    const { reportLanguage, id } = this.state;
    const reportUrl = `/admin/core-pro-report/${id}?reportLanguage=${reportLanguage}&isDownload=true`;
    const reportWindow = window.open(reportUrl, "_blank");

    if (reportWindow) {
      reportWindow.addEventListener("load", () => {
        const checkDataLoaded = setInterval(() => {
          if ((reportWindow as WindowWithDataLoaded).dataLoaded) {
            reportWindow.print();
            setTimeout(() => {
              reportWindow.close();
            }, 1000);
            clearInterval(checkDataLoaded);
          }
        }, 1000);
      });
    } else {
      console.error(
        "Unable to open the report window. It may have been blocked by a popup blocker."
      );
    }
  };

  handleOpenAssignModal = () => {
    this.setState({ isAssignModalVisible: true });
  };

  handleCloseAssignModal = () => {
    this.setState({ isAssignModalVisible: false, newTherapistId: "" });
  };

  handleNewTherapistChange = (therapistId) => {
    this.setState({ newTherapistId: therapistId });
  };

  handleConfirmAssign = () => {
    const { newTherapistId } = this.state;
    if (newTherapistId) {
      this.setState(
        {
          therapistId: newTherapistId,
          isAssignModalVisible: false,
          newTherapistId: "",
        },
        async () => {
          console.log("New Therapist ID set: ", this.state.therapistId);
          await this.handleSubmitAssignTherapist();
        }
      );
    }
  };

  handleSelect = async () => {
    const { newTherapistId } = this.state;

    if (newTherapistId) {
      try {
        this.setState({ therapistId: newTherapistId });
        await this.handleSubmitAssignTherapist(); // call the function here
        this.setState({ isAssignModalVisible: false }); // close modal after assigning
      } catch (error) {
        console.error("Assignment error:", error);
      }
    } else {
      console.warn("Therapist not selected");
    }
  };

  updateVideoData = (updatedVideoData: Partial<typeof this.state>) => {
    this.setState(updatedVideoData);
  };

  render = () => {
    const { Option } = Select;
    const {
      isAssignModalVisible,
      newTherapistId,
      isModalVisible,
      reportLanguage,
    } = this.state;
    const { t } = this.props;
    const { id } = this.props.match.params;
    const {
      showNotification,
      showVideo,
      message,
      notificationStatus,
      completed,
      currentStep,
      productId,
      productName,
      childInfo,
      therapistId,
      transactionId,
      therapistFirstName,
      therapistLastName,
      userId,
      video1,
      video1comment,
      video1aws,
      video2,
      video2comment,
      video2aws,
      videoId,
      encodedVideoName1,
      encodedVideoName2,
      completed1,
      completed2,
      showAssignTherapist,
      therapistList,
      showVideoComment,
      showReportComment,
      startAt,
      finishAt,
      uploadAt,
      CoreProReportId,
      consultationLink,
      consultationTime,
      summary,
      userFirstName,
      userLastName,
      expertReportId,
      expertReportLastSent,
    } = this.state;
    const {
      childName,
      childBirthdayDay,
      childBirthdayMonth,
      childBirthdayYear,
      sex,
    } = childInfo;
    const { year, month } = getYearAndMonth(
      childBirthdayYear,
      childBirthdayMonth,
      childBirthdayDay
    );
    const video = {
      video1,
      video1comment,
      video1aws,
      video2,
      video2comment,
      video2aws,
      encodedVideoName1,
      encodedVideoName2,
      completed1,
      completed2,
      transactionId,
      videoId,
    };

    const isExpert = EXPERT_PRODUCTS_FOR_ADMIN_PANEL.includes(productName);
    const hasReport = !EXPERT_PRODUCTS_WITHOUT_REPORT.includes(productName);
    const isChildInfoOnly = PRODUCTS_WITH_CHILD_INFO_ONLY.includes(productName);
    const isExpertReportReady = expertReportId && expertReportId !== "";
    const isExpertReportLastSent =
      isExpertReportReady &&
      expertReportLastSent &&
      expertReportLastSent !== "";
    const therapistName =
      therapistFirstName && therapistLastName
        ? `${therapistFirstName} ${therapistLastName}`
        : "To be Assigned";
    return (
      <Container>
        <UtcNotice>{t("admin.result.utcNote")}</UtcNotice>
        {/* <AssignTherapistModal
          therapistId={therapistId}
          therapistList={therapistList}
          show={showAssignTherapist}
          handleClose={this.handleCloseAssignTherapistModal}
          handleAssignTherapist={this.handleSubmitAssignTherapist}
          handleChangeTherapist={this.handleChangeTherapist}
        /> */}
        <CommentModal
          title="Tell user about the reason"
          show={showVideoComment}
          handleSubmit={this.handleRejectVideo}
          handleClose={this.handleCloseVideoComment}
          onCommentChange={this.onVideoCommentChange}
        />
        <CommentModal
          title="Tell therapist about your changes"
          show={showReportComment}
          handleSubmit={this.handleSubmitReport}
          handleClose={this.handleCloseReportComment}
          onCommentChange={this.onReportCommentChange}
        />
        <VideoModal
          show={showVideo}
          video={{
            video1: this.state.video1,
            video1comment: this.state.video1comment,
            video1aws: this.state.video1aws,
            video2: this.state.video2,
            video2comment: this.state.video2comment,
            video2aws: this.state.video2aws,
            encodedVideoName1: this.state.encodedVideoName1,
            encodedVideoName2: this.state.encodedVideoName2,
            completed1: this.state.completed1,
            completed2: this.state.completed2,
            transactionId: this.state.transactionId,
            videoId: this.state.videoId,
          }}
          handleSave={this.handleSaveVideo}
          handleClose={this.handleCloseVideoModal}
          handleUploadFile={this.handleUploadFile}
          handleDelete={this.handleDeleteVideo}
          handleUpdateVideoData={this.updateVideoData}
        />
        <NotificationModal
          show={showNotification}
          message={message}
          status={notificationStatus}
        />
        <SubContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.userId")}</ContentSubtitle>
            <LinkButtonInEdit
              onClick={() => {
                this.props.history.push("/admin/user/edit/" + userId);
              }}
            >
              {userId}
            </LinkButtonInEdit>
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.id")}</ContentSubtitle>
            <Input disabled={true} value={id} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.firstName")}</ContentSubtitle>
            <Input disabled={true} value={userFirstName} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.lastName")}</ContentSubtitle>
            <Input disabled={true} value={userLastName} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.productName")}</ContentSubtitle>
            <TextWrapLinkButtonInEdit
              onClick={() => {
                this.props.history.push("/admin/product/edit/" + productId);
              }}
            >
              {productName}
            </TextWrapLinkButtonInEdit>
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.currentStep")}</ContentSubtitle>
            <Input disabled={true} value={currentStep} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.startAt")}</ContentSubtitle>
            <Input type="datetime-local" disabled={true} value={startAt} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.finishAt")}</ContentSubtitle>
            <Input type="datetime-local" disabled={true} value={finishAt} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.childName")}</ContentSubtitle>
            <Input disabled={true} value={childName} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.result.childAge")}</ContentSubtitle>
            <ChildAge disabled={true} value={year} />
            <ChildAgeSubTitle>{t("admin.result.year")}</ChildAgeSubTitle>
            <ChildAge disabled={true} value={month} />
            <ChildAgeSubTitle>{t("admin.result.month")}</ChildAgeSubTitle>
          </ContentContainer>
        </SubContainer>

        {childName && (
          <ChildInfoContainer>
            {!isChildInfoOnly && (
              <>
                <ContentContainer>
                  <ContentSubtitle>{t("admin.result.result")}</ContentSubtitle>
                  <AdminVideo
                    onClick={() => {
                      window.open("/admin/full-result/" + id, "_blank");
                    }}
                  >
                    {t("admin.result.fullQuestionnaire")}
                  </AdminVideo>
                </ContentContainer>
                <ContentContainer>
                  <ContentSubtitle> </ContentSubtitle>
                  <AdminVideo
                    onClick={() => {
                      window.open(
                        "/admin/core-report/" + transactionId,
                        "_blank"
                      );
                    }}
                  >
                    {t("admin.result.resultPage")}
                  </AdminVideo>
                </ContentContainer>
              </>
            )}
            {summary.database && (
              <>
                {/* <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.result.database")}
                  </ContentSubtitle>
                  <Input disabled={true} value={summary.database} />
                </ContentContainer> */}
                <ContentContainer>
                  <ContentSubtitle>{t("admin.result.summary")}</ContentSubtitle>
                  <Input disabled={true} value={summary.calculated} />
                </ContentContainer>
              </>
            )}
          </ChildInfoContainer>
        )}

        {isExpert && (
          <TherapistContainer>
            {!isChildInfoOnly && (
              <>
                <ContentContainer>
                  <ContentSubtitle>{t("admin.result.video")}</ContentSubtitle>
                  <AdminVideo onClick={this.handleOpenVideoModal}>
                    {t("admin.result.videoReview")}
                  </AdminVideo>
                </ContentContainer>

                <ContentContainer>
                  <ContentSubtitle>{t("admin.result.videoId")}</ContentSubtitle>
                  <Input disabled={true} value={videoId} />
                </ContentContainer>

                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.result.uploadAt")}
                  </ContentSubtitle>
                  <Input
                    type="datetime-local"
                    disabled={true}
                    value={uploadAt}
                  />
                </ContentContainer>
              </>
            )}
            <ContentContainer>
              <ContentSubtitle>
                {t("admin.result.therapistName")}
              </ContentSubtitle>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Input
                  disabled={true}
                  value={therapistName}
                  style={{ height: "48px" }}
                />
                <Button
                  type="primary"
                  style={{
                    marginLeft: "8px",
                    backgroundColor: "#6898e3",
                    color: "#ffffff",
                  }}
                  onClick={this.handleOpenAssignTherapistModal}
                >
                  {t("admin.result.assign")}
                </Button>
              </div>
            </ContentContainer>
            <Modal
              title={t("admin.result.assignTherapist")}
              visible={isAssignModalVisible}
              onCancel={this.handleCloseAssignModal}
              footer={[
                <Button key="cancel" onClick={this.handleCloseAssignModal}>
                  {t("admin.result.cancelButton")}
                </Button>,
                <Button
                  key="confirm"
                  type="primary"
                  onClick={this.handleConfirmAssign}
                >
                  {t("admin.result.confirm")}
                </Button>,
              ]}
            >
              <Select
                style={{ width: "100%" }}
                value={newTherapistId}
                onChange={this.handleNewTherapistChange}
                placeholder={t("admin.result.selectTherapist")}
              >
                {therapistList.map((therapist) => (
                  <Select.Option key={therapist.id} value={therapist.id}>
                    {`${therapist.firstName} ${therapist.lastName}`}
                  </Select.Option>
                ))}
              </Select>
            </Modal>

            {hasReport && (
              <>
                <ContentContainer>
                  <ContentSubtitle>{t("admin.result.report")}</ContentSubtitle>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AdminReport
                      onClick={() => {
                        const reportUrl = `/admin/core-pro-report/${id}?reportLanguage=${reportLanguage}`;
                        window.open(reportUrl, "_blank");
                      }}
                      style={{
                        backgroundColor: isExpertReportReady
                          ? "#6898e3"
                          : "grey",
                        cursor: isExpertReportReady ? "pointer" : "not-allowed",
                        pointerEvents: isExpertReportReady ? "auto" : "none",
                      }}
                      disabled={!isExpertReportReady}
                    >
                      {isExpertReportReady
                        ? t("admin.result.reviewReport")
                        : t("admin.result.reportNotYetFinished")}
                    </AdminReport>

                    <>
                      <Tooltip title={t("admin.result.edit")}>
                        <Button
                          icon={<EditOutlined rev={undefined} />}
                          onClick={this.handleEditReport}
                          shape="circle"
                          type="primary"
                          style={{
                            backgroundColor: "#6898E3",
                            color: "#fff",
                            border: "none",
                            boxShadow: "none",
                            padding: "8px",
                            margin: "5px",
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={t("admin.result.downloadReport")}>
                        <Button
                          icon={<DownloadOutlined rev={undefined} />}
                          onClick={this.handleDownloadReport}
                          shape="circle"
                          disabled={!isExpertReportReady}
                          type="primary"
                          style={{
                            backgroundColor: isExpertReportReady
                              ? "#6898E3"
                              : "#d9d9d9",
                            color: isExpertReportReady ? "#fff" : "#8c8c8c",
                            border: "none",
                            boxShadow: "none",
                            padding: "8px",
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={t("admin.result.send")}>
                        <Button
                          icon={<SendOutlined rev={undefined} />}
                          onClick={this.showModal}
                          shape="circle"
                          type="primary"
                          disabled={
                            this.state.sendReport || !isExpertReportReady
                          }
                          style={{
                            marginLeft: "6px",
                            backgroundColor:
                              this.state.sendReport || !isExpertReportReady
                                ? "#d9d9d9"
                                : "#6898E3",
                            color:
                              this.state.sendReport || !isExpertReportReady
                                ? "#8c8c8c"
                                : "#fff",
                            border: "none",
                            boxShadow: "none",
                            padding: "8px",
                            cursor:
                              this.state.sendReport || !isExpertReportReady
                                ? "not-allowed"
                                : "pointer",
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={t("admin.result.changeLanguage")}>
                        <Select
                          disabled={!isExpertReportReady}
                          value={reportLanguage}
                          style={{ width: 120, marginLeft: "8px" }}
                          onChange={this.handleChangeReportLanguage}
                        >
                          <Select.Option value="en">English</Select.Option>
                          <Select.Option value="zh-CN">简体中文</Select.Option>
                          <Select.Option value="zh-TW">繁體中文</Select.Option>

                          {/* Add more language options as needed */}
                        </Select>
                      </Tooltip>
                    </>
                  </div>
                </ContentContainer>
                <ContentContainer>
                  <ContentSubtitle>
                    {t("admin.result.reportId")}
                  </ContentSubtitle>
                  <Input
                    disabled={true}
                    value={
                      isExpertReportReady
                        ? expertReportId
                        : t("admin.result.reportNotYetFinished")
                    }
                  />
                </ContentContainer>
              </>
            )}
          </TherapistContainer>
        )}

        <>
          {hasReport && (
            <ContentContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <SendReportButton
                  onClick={this.showModal}
                  isAlreadySent={this.state.sendReport || !isExpertReportReady}
                  disabled={this.state.sendReport || !isExpertReportReady}
                >
                  {this.state.sendReport
                    ? t("admin.result.sent")
                    : !isExpertReportReady
                    ? t("admin.result.reportNotYetFinished")
                    : t("admin.result.send")}
                </SendReportButton>
                {isExpertReportLastSent && (
                  <div
                    style={{
                      fontSize: "12px",
                      color: "#8c8c8c",
                      marginTop: "4px",
                    }}
                  >
                    {t("admin.result.reportLastSentAt")}:{" "}
                    {this.props.i18n.language.startsWith("zh")
                      ? moment(expertReportLastSent)
                          .locale("zh-cn")
                          .format("YYYY年MM月DD日 HH:mm")
                      : moment(expertReportLastSent)
                          .locale("en")
                          .format("MMMM D, YYYY [at] h:mm A")}
                  </div>
                )}
              </div>
            </ContentContainer>
          )}

          <Modal
            title={t("admin.result.submitModalTitle")}
            open={isModalVisible}
            onCancel={this.handleNo}
            footer={[
              <Button key="no" onClick={this.handleNo}>
                {t("admin.result.cancelButton")}
              </Button>,
              <Button key="yes" type="primary" onClick={this.handleYes}>
                {t("admin.result.yesButton")}
              </Button>,
            ]}
          >
            <p>
              {t("admin.result.submitModalDescription")}
              <br />
              {t("admin.result.submitModalDescription2")}
            </p>
          </Modal>
        </>
      </Container>
    );
  };
}

export default withTranslation()(ResultEdit);
